input[type=radio],
input.radio {
  margin-right: 30px;
}

.radio {
    display: inline;
}

fieldset {
    border: 1px solid rgba(7,84,140,0.9);
    border-radius: 5px;
    padding: 15px;
}

.getGpsButton {
    margin-top: 30px;
}

input[readOnly] {
    border:0;
    font-weight: bold;
}

.all-center {
    position: relative;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
}

.datafield {
    margin-bottom: 10px;
}

#location-accuracy-green {
    color: green;
}

#location-accuracy-yellow {
    color: rgb(207, 207, 0);
}

#location-accuracy-red {
    color: red;
}

.button-area {
    text-align: center;
}

.button-view {
    align-content: center;
    text-align: center;
    margin: 1px;
    font-size: 18px;
    width: 214px;
    text-transform: none;
    background-color: rgba(7,84,140,0.5) !important;
    border-color: rgba(7,84,140,0.5) !important;
}

.button-view:disabled {
    background-color: rgb(7,84,140,1) !important;
    opacity: 1;
}

#load-data-modal {
    position: fixed;
    width: 90%;
    height: 90%;
    border: 1px rgba(7,84,140,0.9) solid;
    top: 50%;
    left: 50%;
    text-align: center;
    font-size: 20px;
    padding: 15px;
    font-weight: bold;
    background: white;
    transform: translate(-50%, -50%);
    z-index: 3;
    overflow: scroll;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    font-size: 10px;
}

.yellow-text {
    color: rgb(207, 207, 0);
}

.yellow .yellow-text {
    color: rgb(122, 122, 0)
}

.red-text {
    color: red;
}

#accuracy-modal {
    position: fixed;
    width: 255px;
    border: 1px rgba(7,84,140,0.9) solid;
    top: 50%;
    left: 50%;
    text-align: center;
    font-size: 20px;
    padding: 15px;
    font-weight: bold;
    background: white;
    transform: translate(-50%, -50%);
    z-index: 2;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    font-size: 10px;
}

#add-small-area-modal {
    position: fixed;
    max-width: 90%;
    max-height: 95%;
    border: 1px rgba(7,84,140,0.9) solid;
    top: 50%;
    left: 50%;
    text-align: center;
    font-size: 20px;
    padding: 15px;
    font-weight: bold;
    background: white;
    transform: translate(-50%, -50%);
    z-index: 2;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    font-size: 10px;
    overflow-y: scroll;
    min-width: 260px;
}

#groove-modal {
    position: fixed;
    width: 90%;
    max-height: 95%;
    border: 1px rgba(7,84,140,0.9) solid;
    top: 50%;
    left: 50%;
    text-align: center;
    font-size: 20px;
    padding: 15px;
    font-weight: bold;
    background: white;
    transform: translate(-50%, -50%);
    z-index: 2;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    font-size: 10px;
    overflow-y: scroll;
    min-width: 260px;
}

.add-small-area-button {
    background: green;
}

.add-small-area-button:hover {
    background: darkgreen;
}

.mass-select {
    border: 1px rgba(7, 84, 140, 0.5) solid;
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
    width: 100%;
}

.mass-select:hover {
    background: rgba(7, 84, 140, 0.5) !important;
    color: white;
    cursor: pointer;
}

.mass-select.green {
    background: green;
    color: white !important;
}

.mass-select.light-green {
    background: lightgreen;
}

.mass-select.selected {
    background: rgba(7, 84, 140, 0.9);
    color: white;
    font-weight: bold;
}

.mass-select-item {
    display: inline;
    padding-left: 6px;
    padding-right: 6px;
}

#top-info {
    position: fixed;
    top: 0;
    left: 45px;
    margin-right: 50px;
    color: white;
    z-index: 3;
}

#top-info div {
    display: inline;
}

.top-text {
    font-size: 15px;
    line-height: 25px;
    display: inline-block;
    padding-left: 3px;
    padding-right: 3px;
}

#crosshair-data {
    border-radius: 5px;
    background: rgba(7,84,140,0.9);
    color: white;
    font-size: 14px;
    font-weight: bold;
    width: 140px;
    padding: 2px;
}

#groove-select {
    border-top: 1px rgba(7,84,140,0.9) solid;
    border-bottom: 1px rgba(7,84,140,0.9) solid;
    background: rgba(7,84,140,0.2);
}

#groove-select hr {
    position: absolute;
    top: 80px;
    left: 110px;
    width: calc(100% - 130px);
    margin: 0;
    border-top: rgba(7,84,140,0.9) dashed 12px;
    pointer-events: none;
    z-index: -1;
}

.groove-option {
    text-align: left;
    font-size: 14px;
    padding-bottom: 8px;
    padding-top: 8px;
    margin: 0;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

.groove-option:hover {
    background: rgba(7,84,140,0.2);
}

.groove-option.selected {
    background: rgba(7,84,140,0.4);
}

#location-on-road {
    color: rgba(7,84,140,1);
    font-size: 14px;
    background: rgba(7,84,140,0.6);
    color: white;
}

.borders {
    border: 1px rgba(7,84,140,0.9) solid;
    border-radius: 5px;
    padding: 4px;
    margin-bottom: 2px;
}
