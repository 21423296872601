#weather {
  position: fixed;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 170px;
  height: 110px;
  z-index: 4;
  background-color: white;
  line-height: 20px;
  border: 1px solid rgb(7,84,140);
  padding-left: 2px;
}

#weather .weather-symbol {
  height: 40px;
  width: 40px;
}

#weather span {
  font-size: 16px;
}

#weather-overall {
  position: fixed;
  top: -16px;
  right: 2px;
  cursor: pointer;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

#weather-overall .weather-symbol {
  height: 32px;
  width: 32px;
  float: left;
}

#weather-overall span {
  font-size: 18px;
  line-height: 0px;
  color: white;
}

.weather-symbol {
  background-repeat: no-repeat;
  background-size: cover !important;
  margin: auto;
}

.img1 {
  background-image: url('./symbols/1.gif');
}

.img2 {
  background-image: url('./symbols/2.gif');
}

.img3 {
  background-image: url('./symbols/3.gif');
}

.img21 {
  background-image: url('./symbols/21.gif');
}

.img22 {
  background-image: url('./symbols/22.gif');
}

.img23 {
  background-image: url('./symbols/23.gif');
}

.img31 {
  background-image: url('./symbols/31.gif');
}

.img32 {
  background-image: url('./symbols/32.gif');
}

.img33 {
  background-image: url('./symbols/33.gif');
}

.img41 {
  background-image: url('./symbols/41.gif');
}

.img42 {
  background-image: url('./symbols/42.gif');
}

.img43 {
  background-image: url('./symbols/43.gif');
}

.img51 {
  background-image: url('./symbols/51.gif');
}

.img52 {
  background-image: url('./symbols/52.gif');
}

.img53 {
  background-image: url('./symbols/53.gif');
}

.img61 {
  background-image: url('./symbols/61.gif');
}

.img62 {
  background-image: url('./symbols/62.gif');
}

.img63 {
  background-image: url('./symbols/63.gif');
}

.img64 {
  background-image: url('./symbols/64.gif');
}

.img71 {
  background-image: url('./symbols/71.gif');
}

.img72 {
  background-image: url('./symbols/72.gif');
}

.img73 {
  background-image: url('./symbols/73.gif');
}

.img81 {
  background-image: url('./symbols/81.gif');
}

.img82 {
  background-image: url('./symbols/82.gif');
}

.img83 {
  background-image: url('./symbols/83.gif');
}

.img91 {
  background-image: url('./symbols/91.png');
}

.img92 {
  background-image: url('./symbols/92.png');
}
