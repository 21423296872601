#loads {
    border: 1px solid #005490;
    padding: 5px;
    max-height: 184px;
    overflow-y: scroll;
    text-align: center;
}

.load {
    background-color: #0095FF;
    border-radius: 5px;
    color: white;
    margin-bottom: 5px;
    padding: 2px;
    font-size: 15px;
    line-height: 25px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.load.selected {
    background-color: #005490;
}

.load:hover {
    background-color: #006CB9;
    cursor: pointer;
}

.load.selected:hover {
    background-color: #005490;
}